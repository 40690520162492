<template>
  <v-app>
    <v-app-bar app color="#2c3e50" dark elevation="0">
      <div class="d-flex align-center">
        ntropical
      </div>
      <v-spacer></v-spacer>
      <ul class="nav-links">
        <a href="/issues" class="white--text nav-link">
          <li>GAMES</li>
        </a>

        <a href="/renders/001/" class="white--text nav-link">
          <li>ABOUT</li>
        </a>
      </ul>
    </v-app-bar>

    <v-main>

      <v-container fill-height fluid class="white--text">

        <v-row flex class="justify-center">
          <div class="text-sm-h1">Futbolinis</div>
        </v-row>

        <v-row flex class="justify-center">
          <div class="text-h2">The internet's football league</div>
        </v-row>

        <v-row flex class="justify-center">
          <div class="text-h3">Decide,everything</div>
        </v-row>
        <v-row flex class="justify-center">
          <div class="text-h4">Coming soon</div>
        </v-row>

        <v-row flex class="justify-center">
          <div class="text-h4">Be the first one to hear about it!</div>
        </v-row>

        <v-row flex class="justify-center">
          <v-col>
            <v-text-field label="Enter your e-mail"></v-text-field>
          </v-col>
          <v-col>
            <v-btn elevation="2">Suscribe</v-btn>
          </v-col>
        </v-row>


      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>


<style>
body {
  background-color: #2c3e50;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  background-color: #2c3e50;
}

header {
  font-family: 'ClashDisplay-Variable', Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: .15em;
}


@font-face {
  font-family: 'ClashDisplay-Variable';
  src: url('./assets/fonts/ClashDisplay-Variable.woff2') format('woff2'),
    url('./assets/fonts/ClashDisplay-Variable.woff') format('woff'),
    url('./assets/fonts/ClashDisplay-Variable.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
}


#main-prompt {
  font-size: 160px;
}


.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


a {
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.nav-link {
  display: flex;
  font-size: .85rem;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
  letter-spacing: .1em;
  line-height: .8;
  position: relative;
  text-transform: uppercase;
}
</style>
